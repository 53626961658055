import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, email, maxLength, sameAs, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'vendors.create',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        phone: '',
        email: '',
        address: '',
        translations: [],
      },
      currentTranslations: 0,
      validationErrors: {}
    }
  },
  components: {},
  validations: {
    payload: {
      phone: {
        required
      },
      email: {
        required,
        email,
        maxLength: maxLength(40),
      },
      address: {
        required,
        maxLength: maxLength(60),
      },
      translations: {
        $each: {
          title: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20)
          },
          description: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(60)
          }
        }
      }
    }
  },
  created() {
    this._applyData();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isVendorCreating: 'vendors/isVendorsLoading',
    }),
    ...mapGetters([
      'languages',
    ]),
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax',{count: 40}));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    addressErrors() {
      let error = [];
      if (!this.$v.payload.address.$dirty) {
        return error;
      }
      if (!this.$v.payload.address.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.address.maxLength) {
        error.push(this.$t('validationMax',{count: 60}));
      }
      if (this.validationErrors.address) {
        this.validationErrors.address.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      createVendor: 'vendors/VENDORS_REQUEST_CREATE'
    }),
    _applyData() {
      this.payload.translations = this.languages.map(langItem => {
        return {
          locale: langItem.translationKey,
          title: '',
          description: '',
        }
      });
    },
    translationsTitleErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].title.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].title.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].title.minLength) {
        error.push(this.$t('validationMin',{count: 1}));
      }
      if (!this.$v.payload.translations.$each[index].title.maxLength) {
        error.push(this.$t('validationMax',{count: 20}));
      }
      return error;
    },
    translationsDescriptionErrors(index) {
      let error = [];
      if (!this.$v.payload.translations.$each[index].description.$dirty) {
        return error;
      }
      if (!this.$v.payload.translations.$each[index].description.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.translations.$each[index].description.minLength) {
        error.push(this.$t('validationMax',{count: 2}));
      }
      if (!this.$v.payload.translations.$each[index].description.maxLength) {
        error.push(this.$t('validationMax',{count: 60}));
      }
      return error;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let payload = {
          phone: this.payload.phone,
          email: this.payload.email,
          address: this.payload.address,
        }
        this.payload.translations.forEach(item => {
          payload[item.locale] = {
            title: item.title,
            description: item.description,
          }
        });

        this.createVendor(payload).then(() => {
          this.$toasted.success(this.$t('vendorsCreated'));
          this.$router.push({name: 'vendors.list'}).catch(()=> {console.log()})
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    },
  }
}
